var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-form',{staticClass:"q-gutter-md",on:{"submit":_vm.onSubmit}},[_c('q-input',{staticClass:"q-mb-lg",attrs:{"dense":"","outlined":"","readonly":"","type":"text"},model:{value:(_vm.destination),callback:function ($$v) {_vm.destination=$$v},expression:"destination"}}),_c('q-input',{ref:"code",attrs:{"dense":"","outlined":"","no-error-icon":"","placeholder":"Código","mask":"######","type":"text","rules":[
      () => _vm.$v.credentials.code.required || 'Código é obrigatório',
      () => _vm.$v.credentials.code.minLength || 'Código inválido'
    ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.credentials.code),expression:"credentials.code"}],staticClass:"cursor-pointer",attrs:{"size":"xs","name":"mdi-close"},on:{"click":function($event){_vm.credentials.code = ''}}})]},proxy:true},{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"mdi-shield-key-outline","size":"xs"}})]},proxy:true}]),model:{value:(_vm.credentials.code),callback:function ($$v) {_vm.$set(_vm.credentials, "code", $$v)},expression:"credentials.code"}}),_c('q-input',{ref:"password",attrs:{"dense":"","outlined":"","no-error-icon":"","autocorrect":"off","autocapitalize":"off","autocomplete":"off","spellcheck":false,"hide-bottom-space":"","placeholder":"Nova Senha","type":_vm.isPassword ? 'password' : 'text',"rules":[
      () => _vm.$v.credentials.password.required || 'Senha é obrigatória',
      () => _vm.$v.credentials.password.minLength || 'Senha inválida'
    ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"mdi-lock-reset","size":"xs"}})]},proxy:true},{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"size":"xs","name":_vm.isPassword ? 'mdi-eye-off' : 'mdi-eye'},on:{"click":function($event){_vm.isPassword = !_vm.isPassword}}})]},proxy:true}]),model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", $$v)},expression:"credentials.password"}}),_c('q-input',{attrs:{"dense":"","outlined":"","no-error-icon":"","autocorrect":"off","autocapitalize":"off","autocomplete":"off","spellcheck":false,"hide-bottom-space":"","placeholder":"Confirmar Senha","type":_vm.isPassword ? 'password' : 'text',"rules":[
      () => _vm.$v.credentials.passwordConfirm.sameAsPassword || 'Senha não confere'
    ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"mdi-shield-check-outline","size":"xs"}})]},proxy:true},{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"size":"xs","name":_vm.isPassword ? 'mdi-eye-off' : 'mdi-eye'},on:{"click":function($event){_vm.isPassword = !_vm.isPassword}}})]},proxy:true}]),model:{value:(_vm.credentials.passwordConfirm),callback:function ($$v) {_vm.$set(_vm.credentials, "passwordConfirm", $$v)},expression:"credentials.passwordConfirm"}}),_c('div',{staticClass:"row items-center justify-between"},[_c('q-btn',{attrs:{"flat":"","dense":"","no-caps":"","padding":"0","ripple":false,"color":"primary","label":"Voltar","to":_vm.authLoginRoute}}),_c('AuthSubmit',{attrs:{"label":"Alterar senha","loading":_vm.auth.loading}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }